import {useCallback, useEffect, useState} from "react";
import AppBar from "../../components/AppBar/AppBar";
import FilesAppLayout from "../../components/FilesAppLayout/FilesAppLayout";
import "@cloudscape-design/global-styles/index.css"

import {defaultBreadcrumbs} from "../../components/breadcrumbs-items";
import {
    AppLayout,
    Box, BreadcrumbGroup,
    Button,
    ColumnLayout,
    Container, ContentLayout, Flashbar,
    FormField,
    Input,
    SpaceBetween, Spinner,
    Table
} from "@cloudscape-design/components";
import Header from "@cloudscape-design/components/header";
import Wizard from "@cloudscape-design/components/wizard";
import {Storage} from "aws-amplify";

const columnDefinitions = [
    {
        id: 'key',
        cell: item => item.key,
        header: 'Filename',
    },
    {
        id: 'size',
        header: 'Size',
        cell: item => (item.size / 1024 / 1024).toFixed(2) + " MB",
        minWidth: 10,
    },
    {
        id: 'lastModified',
        header: 'Last Modified',
        cell: item => item.lastModified.toString(),
    },
];

export default function Answer(props) {

    //items = documentos que son traidos de S3
    const [selectedItems, setSelectedItems] = useState([]);
    const [items, setItems] = useState();

    const [question, setQuestion] = useState([]);
    const [totalQuestions, setTotalQuestions] = useState(1);

    const [submitted, setSubmitted] = useState(false);
    const [answers, setAnswers] = useState([]);

    const [activeStepIndex, setActiveStepIndex] = useState(0);

    const load = async () => {
        Storage.list('', {level: props.level})
            .then(
                result => {
                    //setItems(result.results);
                    var temp_results = [];

                    result.results.map( each => {
                        var name = each.key.split('.');
                        if (name[name.length - 1] === 'pdf') {
                            temp_results.push(each);
                        }
                    });

                    setItems(temp_results);

                }
            )
            .catch((err) => console.log(err));
    };

    useEffect(() => {
        load();
    }, []);

    const updateInputsArray = (index, newValue) => {
        const updatedArray = [...question];
        updatedArray[index] = newValue;
        setQuestion(updatedArray);
    }

    const submitAllQuestions = () => {

        if (submitted){
            setSelectedItems([]);
            setQuestion([])
            setTotalQuestions(1);
            setAnswers([]);
            setSubmitted(false);
            return;
        }

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        question.map( each => {
            var json_request = {
                "document_1": "public/" + selectedItems[0]['key'],
                "question": each
            };

            if (selectedItems.length >= 2){
                json_request["document_2"] = "public/" + selectedItems[1]['key'];
            }

            var raw = JSON.stringify(json_request);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            //api-llm.metro.kevincortes.cloud
            //https://api-llm.slg.kevincortes.cloud/ask
            //fetch("http://CdkCon-llmal-OSusFONL8JH0-1204939092.us-east-1.elb.amazonaws.com/ask", requestOptions)
            fetch("https://genai.chileatiende.cl/ask", requestOptions)
                .then(response => {
                    response.text().then(
                        (text) => {
                            var array_response = {
                                "question" : each,
                                "answer" : JSON.parse(text).summary
                            };

                            setAnswers(prevArray => [...prevArray, array_response]);
                        }
                    )
                })
                .catch(error => console.log('error', error));
        })

        setSubmitted(true);

    }

    return (
        <>
            <AppBar/>

            <AppLayout
                toolsHide="true"
                navigationHide="true"
                breadcrumbs={ <BreadcrumbGroup items={[]}/> }
                content={
                    <Wizard
                        i18nStrings={{
                            stepNumberLabel: stepNumber =>
                                `Step ${stepNumber}`,
                            collapsedStepsLabel: (stepNumber, stepsCount) =>
                                `Step ${stepNumber} of ${stepsCount}`,
                            skipToButtonLabel: (step, stepNumber) =>
                                `Skip to ${step.title}`,
                            navigationAriaLabel: "Steps",
                            previousButton: "Previous",
                            nextButton: "Next",
                            submitButton: ( !submitted ? "Upload" : "Cancel") ,
                            optional: "optional"
                        }}
                        onNavigate={({detail}) =>
                            setActiveStepIndex(detail.requestedStepIndex)
                        }
                        onSubmit={submitAllQuestions}
                        onCancel={()=>setSubmitted(false)}
                        activeStepIndex={activeStepIndex}
                        steps={[
                            {
                                title: "Choose documents",
                                description: "Only PDF files",
                                content: (
                                    <SpaceBetween size="l">
                                        <Table
                                            columnDefinitions={columnDefinitions}
                                            items={items}
                                            onSelectionChange={({detail}) =>
                                                setSelectedItems(detail.selectedItems)
                                            }
                                            selectedItems={selectedItems}
                                            loadingText="Loading resources"
                                            selectionType="multi"
                                            resizableColumns
                                            empty={
                                                <Box
                                                    margin={{vertical: "xs"}}
                                                    textAlign="center"
                                                    color="inherit"
                                                >
                                                    <SpaceBetween size="m">
                                                        <b>No resources found</b>
                                                    </SpaceBetween>
                                                </Box>
                                            }
                                            header={
                                                <Header
                                                    actions={
                                                        <SpaceBetween size="xs" direction="horizontal">
                                                            <Button variant="primary"
                                                                    onClick={ () => {
                                                                        console.log(selectedItems)
                                                                    }}
                                                                    disabled={ selectedItems.length > 2 ? true : false }
                                                            >
                                                                Select {selectedItems.length} items
                                                            </Button>
                                                        </SpaceBetween>
                                                    }> All documents uploaded </Header>
                                            }
                                        />
                                    </SpaceBetween>
                                )
                            },
                            {
                                title: "Upload questions",
                                description: ('We will answer the questions based in ' + selectedItems.length + (selectedItems.length > 1 ? ' documents' : ' document')),
                                content: (
                                    (
                                        submitted == true ?
                                            <Container
                                                header={
                                                    <Header variant="h2">
                                                        Your responses
                                                    </Header>
                                                }
                                            >
                                                <SpaceBetween direction="vertical" size="l">
                                                    <Flashbar items={[{
                                                        type: "warning",
                                                        content: "Remember to check all your questions and answers before posting them. The answers will appear progressively as the GenAI responds.",
                                                        id: "check_answers"
                                                    }]} />
                                                    {
                                                        answers.length === 0 ?
                                                            <Spinner size="large" />
                                                            :
                                                            answers.map( each => (
                                                                <>
                                                                    <span><b>QUESTION: {each.question.toUpperCase()}</b></span>
                                                                    <span>ANSWER: {each.answer}</span>
                                                                </>
                                                            ))

                                                    }
                                                </SpaceBetween>
                                            </Container>
                                            :
                                            <Container
                                                header={
                                                    <Header variant="h2">
                                                        Write your questions
                                                    </Header>
                                                }
                                            >
                                                <SpaceBetween direction="vertical" size="l">
                                                    {
                                                        Array.from(Array(totalQuestions).keys()).map(item =>
                                                            <>
                                                                <FormField label={"Question " + (item + 1)}>
                                                                    <Input
                                                                        onChange={({detail}) => updateInputsArray(item, detail.value)}
                                                                        value={question[item]}
                                                                    />
                                                                </FormField>
                                                            </>
                                                        )
                                                    }

                                                    <Button
                                                        onClick={() => {
                                                            setTotalQuestions(totalQuestions + 1)
                                                        }}>
                                                        Add a new question
                                                    </Button>
                                                </SpaceBetween>
                                            </Container>

                                    )
                                )
                            },
                        ]}
                    />
                }/>

        </>
    );

}