import {useCallback, useState} from "react";
import AppBar from "../../components/AppBar/AppBar";
import FilesAppLayout from "../../components/FilesAppLayout/FilesAppLayout";
import "@cloudscape-design/global-styles/index.css"

import {defaultBreadcrumbs} from "../../components/breadcrumbs-items";
import {
    Box,
    Button,
    Container,
    Form,
    FormField,
    Input,
    SpaceBetween, Spinner,
    Table
} from "@cloudscape-design/components";
import Header from "@cloudscape-design/components/header";
import {Link} from "react-router-dom";
import {Storage} from "aws-amplify";

export default function Search(props) {

    const [searchText, setSearchText] = useState('');
    const [maxResults, setMaxResults] = useState(10);
    const [searchResult, setSearchResult] = useState([]);
    const [canSync, setCanSync] = useState(true);
    const [selectedItems, setSelectedItems] = useState([]);

    const [compareText, setCompareText] = useState('');
    const [hasCompareText, setHasCompareText] = useState(false);

    const onSearchTextChange = useCallback(event => setSearchText(event.detail.value), []);
    const onMaxResultsChange = useCallback(event => setMaxResults(event.detail.value), []);

    function postSearchText() {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "query": searchText,
            "num_results": maxResults
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch("https://genai.chileatiende.cl/knowledge", requestOptions)
            .then(response => response.text().then(
                (text) => {
                    var searchResponse = JSON.parse(text).search_response;
                    var tempArraySearchResponse = [];

                    searchResponse.forEach((each) => {
                            var name = each.uri.split("public/")[1];

                            tempArraySearchResponse.push({
                                'uri': name,
                                'content': each.content.slice(0, 100),
                                'score': each.score
                            })

                        }
                    );

                    setSearchResult(tempArraySearchResponse);
                })
            )
            .then(result => console.log(result))
            .catch(error => console.log('error', error));
    }

    function postCompareDocuments(doc_1, doc_2) {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        setHasCompareText(true);

        var raw = JSON.stringify({
            "document_1": "public/" + doc_1,
            "document_2": "public/" + doc_2
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch("https://genai.chileatiende.cl/doc", requestOptions)
            .then(response => {
                response.text().then(
                    (text) => setCompareText(JSON.parse(text).summary)
                )
            })
            .then(result => console.log(result))
            .catch(error => console.log('error', error));
    }

    function downloadFile(filename) {
        Storage.get(filename, {
            level: props.level
        }).then(
            (result) => {
                openInNewTab(result);
            }
        )
    }

    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    function syncKB() {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch("https://genai.chileatiende.cl/sync", requestOptions)
            .then(response => response.text())
            .then(result => {
                setCanSync(false);
            })
            .catch(error => console.log('error', error));
    }

    return (
        <>
            <AppBar/>
            <FilesAppLayout
                breadcrumbs={defaultBreadcrumbs}
                title='Search Documents'
            >

                <SpaceBetween size="l">
                    <Container>
                        <SpaceBetween size="l">
                            <form onSubmit={e => e.preventDefault()}>
                                <Form
                                    actions={
                                        <SpaceBetween direction="horizontal" size="xs">

                                            <Button
                                                formAction="none"
                                                variant="normal"
                                                disabled={!canSync}
                                                onClick={() => {
                                                    syncKB()
                                                }}

                                            >
                                                Sync knowledge base
                                            </Button>

                                            <Button
                                                variant="primary"
                                                onClick={() => {
                                                    postSearchText()
                                                }}
                                            >Search</Button>
                                        </SpaceBetween>
                                    }
                                >

                                    <SpaceBetween direction="vertical" size="l">
                                        <FormField label="Insert text here">
                                            <Input
                                                value={searchText}
                                                onChange={onSearchTextChange}
                                                placeholder="Search information about Jeff Bezos"/>
                                        </FormField>

                                        <FormField label="Max results">
                                            <Input
                                                value={maxResults}
                                                disabled
                                                onChange={onMaxResultsChange}
                                                placeholder="10"/>
                                        </FormField>
                                    </SpaceBetween>

                                </Form>
                            </form>
                        </SpaceBetween>
                    </Container>

                    <Table
                        columnDefinitions={[
                            {
                                id: "uri",
                                header: "Filename",
                                cell: item => (
                                    <Link href="#"
                                          onClick={() => downloadFile(item.uri)}
                                    >{item.uri || "-"}</Link>
                                ),
                                sortingField: "uri",
                                isRowHeader: true
                            },
                            {
                                id: "content",
                                header: "Content",
                                cell: item => item.content || "-",
                                sortingField: "content"
                            },
                            {
                                id: "score",
                                header: "Score",
                                cell: item => item.score || "-"
                            }
                        ]}
                        items={searchResult}
                        onSelectionChange={({detail}) =>
                            setSelectedItems(detail.selectedItems)
                        }
                        selectedItems={selectedItems}
                        loadingText="Loading resources"
                        selectionType="multi"
                        sortingDisabled
                        resizableColumns
                        empty={
                            <Box
                                margin={{vertical: "xs"}}
                                textAlign="center"
                                color="inherit"
                            >
                                <SpaceBetween size="m">
                                    <b>No resources found</b>
                                </SpaceBetween>
                            </Box>
                        }
                        header={
                            <Header
                                actions={
                                    <SpaceBetween size="xs" direction="horizontal">
                                        <Button variant="primary"
                                                onClick={() => {
                                                    postCompareDocuments(selectedItems[0]['uri'], selectedItems[1]['uri'])
                                                }}
                                                disabled={selectedItems.length !== 2}
                                        >
                                            Compare
                                        </Button>
                                    </SpaceBetween>
                                }
                            > Search results </Header>
                        }
                    />

                    {
                        !hasCompareText ?
                            <></> :
                            <Container
                                header={
                                    <Header
                                        variant="h2"
                                    >
                                        Comparison
                                    </Header>
                                }>
                                <SpaceBetween size="l">
                                    {compareText.length === 0 ? <Spinner size="large" /> : compareText}
                                </SpaceBetween>
                            </Container>
                    }

                </SpaceBetween>

            </FilesAppLayout>

        </>
    );

}