import {useEffect, useState} from "react";

import "@cloudscape-design/global-styles/index.css"
import {
    Button,
    Container,
    SpaceBetween,
} from "@cloudscape-design/components"


function MainOptionsInMenuCard(props) {

    return (
        <Container>
            <SpaceBetween direction="horizontal" size="s">
                    <Button fullWidth href={"#/answer"}>
                        Answer questions
                    </Button>

                    <Button href={"#/search"}>
                        Search in knowledge base
                    </Button>
            </SpaceBetween>
        </Container>
    );

}

export default MainOptionsInMenuCard;
